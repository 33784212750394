import "../styles/globals.scss"
import "../styles/fonts.scss"
import type { AppProps } from "next/app"
import classNames from "classnames"
import Head from "next/head"
import { useRouter } from "next/router"
import { getPageTitle } from "../utils/html"

function App({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const { articleSlug } = router.query
  const randomize = () => {
    // if (articleSlug) {
    //   const article = articles.find(
    //     (article) => article.metadata.slug === articleSlug
    //   )
    //   article?.randomizePairing()
    // }
  }
  return (
    <>
      <Head>
        <title>{getPageTitle()}</title>
        <meta name="description" content="Generated by create next app" />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon-96x96.png"
        ></link>
      </Head>
      <div className={classNames("App")}>
        <div className="wrapper">
          <Component {...pageProps} />
        </div>
      </div>
    </>
  )
}

export default App

export const nbsp = (text: string = "") => {
  return text.replace(/&nbsp;/g, "\u00a0")
}
export const removeHTMLEntities = (text: string = "") => {
  if (text) {
    text = text.replace(/\&nbsp\;/g, " ")
    text = text.replace(/&[^;]+;/g, "")
    return text
  } else {
    return text
  }
}
export const getPageTitle = (text: string = "") => {
  return [removeHTMLEntities(text), "The Food Issue", "Commercial Type"]
    .filter(Boolean)
    .join(" | ")
}
